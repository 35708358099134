import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { ExternalLink, EmailLink } from "../components/sitelinks";
import {
    MainContainer,
    BoxContainer,
    BoxHeading,
    SubHeading,
    BoxCopy
} from "../components/simplepagelayout";


const ContourPatchNotesPage = () => (
        <Layout>
        <SEO title= "Contour Rig Tools Patch Notes" />
        <MainContainer>
          <BoxContainer>
        <BoxHeading>
        Contour Rig Tools Patch Notes
        </BoxHeading>
        <BoxCopy>
        <p>
        Below you'll find all patch notes for Contour Rig Tools in reverse chronological order. More recent patches are displayed first.
        </p>

        <SubHeading>
          v1.0.11.1 (6/10/2024)
        </SubHeading>

        <ul>
          <li>
            Fixed bug where the warning about importing or referencing a scene with Contour shape nodes could appear repeatedly
          </li>
        </ul>

        <SubHeading>
          v1.0.11 (5/24/2024)
        </SubHeading>

        <ul>
          <li>
          Now warns when importing or referencing a scene that contains Contour shape nodes due to the performance problems caused by custom shape nodes
          </li>
          <li>
            Fixed a bug where a rig can fail to evaluate correctly right after importing or referencing its scene when it contains Contour shape nodes
          </li>
          <li>
            Fixed a bug where the Contour paint tool stopped working if one of Maya's built in deformer painting tools was used first in the same session
          </li>
          <li>
            Fixed a bug where a Contour deformer would not rebind when changing the parent space of its mesh
          </li>
          <li>
            Fixed a bug where a callback would not be removed when the plug-in is unloaded
          </li>
        </ul>

        <SubHeading>
          v1.0.10 (2/7/2024)
        </SubHeading>

        <ul>
          <li>
          We now display a warning to the user when they incorrectly delete a Contour joint, bone, or bone CV without using the Contour menu, with an option to undo the deletion and fix the scene
          </li>
        </ul>

        <SubHeading>
          v1.0.9 (11/24/2023)
        </SubHeading>

        <ul>
          <li>
            Rendering in headless Maya no longer requires PyMEL to be installed
          </li>
          <li>
            No longer show EULA dialog in headless mode / batch rendering, which fixes a possible crash when batch rendering on Maya installations that have not accepted the EULA
          </li>
          <li>
            Removed check for PyMEL when loading the plug-in in headless mode, which fixes a possible crash when batch rendering on Maya installations that do not have PyMEL installed
          </li>
        </ul>

        <SubHeading>
          v1.0.8 (11/1/2023)
        </SubHeading>

        <ul>
          <li>
            "Create Contour Deform Field Constraints" menu allows creating multiple constraints at once.
          </li>
          <li>
            Can now bind multiple meshes at once from the Contour Rig Tools menu
          </li>
          <li>
            Can now mirror weights between meshes
          </li>
          <li>
            The predeprecated API now has a function for mirroring weights between meshes.
          </li>
          <li>
            We now lock the attributes cvIsJoint on ContourSpline, isBind on ContourDeformField, influences.influenceType on ContourDeformer, and interveningTransforms.transformType on ContourIKSolver.
          </li>
          <li>
            We now hide the attributes weightProxy and weightProxyChainIndex on ContourDeformer.
          </li>
          <li>
            Fixed a bug that could cause a bind spline to have incorrect data when created with the Python API when the corresponding active ContourSpline had not been evaluated since changes had been made to its joint chain
          </li>
          <li>
            Rebind mode now has an overlay indicating when you are in rebind mode.
          </li>
          <li>
            Contour now ships with a Python module named "Contourlib.users.utilities" that contains a function named "createLoadReferencesScriptNode" that, when called, adds a script node to the scene that will load all references in the scene. <ExternalLink href="https://manuals.notionalpipe.com/rigtools-single-page/index.html#referencing-a-rig-with-contour-constraints-more-than-once-in-the-same-scene-with-cached-playback-enabled">(See this section of the manual.)</ExternalLink>
          </li>
          <li>
            Fixed a bug that caused joint sockets to change orientation when using the joint draw tool to add joints to chains that already have bones
          </li>
          <li>
            Fixed a bug that could cause deformers to become broken when undoing the addition of an SSD influence
          </li>
          <li>
            Fixed "The -bs/-borderStyle flag is obsolete and should not be used" warning when opening the Contour weight painting tool
          </li>
          <li>
            Added a warning message that appears when using Maya 2023.0 or 2023.1, as Contour Rig Tools does not work in those Maya versions
          </li>
          <li>
            Fixed incorrect error message when attempting to edit Contour joints with non-zero translation using the Contour joint tool
          </li>
          <li>
            Deleting Contour Aim handles now deletes any associated Contour Orients that use the Aim locator as a driver.
          </li>
          <li>
            Fixed an error that occured the first time the "Mirror joint chain" options box was selected in Maya 2023 and later
          </li>
        </ul>

        <SubHeading>
          v1.0.7 (11/9/2022)
        </SubHeading>

        <ul>
          <li>
            Trials can now be extended. If you would like to request a trial extension, please contact us at <EmailLink href="mailto: support@notionalpipe.com">support@notionalpipe.com</EmailLink>.
          </li>
          <li>
            Added the menu item <em>Contour Rig Tools > Deform > Bind Mesh To Influences and Descendant Contour Chains</em>. When chosen, the selected influences, and any Contour joint chains below them in the DAG hierarchy will be bound to the selected mesh
          </li>
          <li>
            Added a named argument, <code>bindDescendantChains</code>, to the API function <code>bindMeshToDeformer</code>. When truthy, the influences passed to the function, and any Contour joint chains below them in the DAG hierarchy, will be bound to the mesh passed to the function. When falsy, only the influences passed to the function will be bound. Defaults to False.
          </li>
          <li>
            When rebinding in a scene with incorrectly connected Contour deformers, we now check for more error conditions, and display more informative error messages. When rebinding using the API, a custom exception, <code>ContourDegenerateSceneGraphError</code> will be thrown. Previously, runtime errors from Maya were thrown in this case.
          </li>
          <li>
            Fixed a bug where, in certain cases, Contour Joints could become unmanipulable after keyframing them
          </li>
          <li>
            Renamed the menu item <em>Contour Rig Tools > Deform > Bind Contour Deformer To Mesh</em> to <em>Contour Rig Tools > Deform > Bind Mesh To Influences</em>
          </li>
          <li>
            Renamed the menu item <em>Contour Rig Tools > Deform > Unbind Contour Deformer From Meshes</em> to <em>Contour Rig Tools > Deform > Unbind Meshes</em>
          </li>
        </ul>

        <SubHeading>
           v1.0.6.1 (9/3/2022)
        </SubHeading>

        <ul>
          <li>
             Added functions to create and delete aim handles and orient to the predeprecated API
          </li>
          <li>
             Added menu items to the main menu for deleting aim handles and orients
          </li>
          <li>
             Added an instructions overlay for the Joint Tool
          </li>
          <li>
             When creating an IK system, either from the menu or using the predeprecated API, if a transform is supplied for the IK handle, its transform will no longer be set to match that of the last transform in the IK hierarchy.
          </li>
        </ul>

        <SubHeading>
           v1.0.6 (5/23/2022)
        </SubHeading>

        <ul>
          <li>
            Referencing the same file more than once in a scene now works in most cases. In one of our test cases this causes Maya to crash when playback caching is enabled, and we are investigating. This only occurs when the references are loaded at scene load, and can be worked around by waiting to load the references until after the scene has loaded.
          </li>

          <li>
            Worked around bugs in the Maya API that could cause Contour transform nodes to calculate incorrectly when attributes had both incoming and outgoing connections
          </li>

          <li>
            Fixed a bug that sometimes caused changes in the bone axis of a joint chain to not propagate to all bones in the chain
          </li>

          <li>
            macOS installer no longer depends on Python being installed, appears as a normal macOS application, and is notarized
          </li>
        </ul>

        <SubHeading>
           v1.0.5 (1/12/2022)
        </SubHeading>

        <ul>
          <li>
            When creating Contour Aim Handles or Contour Orients, overrideRotation and overrideBlend are locked
          </li>
        </ul>

        <SubHeading>
           v1.0.4 (12/13/2021)
        </SubHeading>

        <ul>
          <li>
            Extend the pole vector of Contour IK systems from the elbow, not the shoulder
          </li>
          <li>
            Buffer both the rotation and translation of IK handles when creating Contour IK systems
          </li>
          <li>
             Spline and deform field constraints now work correctly for nodes with scaled parent spaces, non-zero rotate pivot translates, and Maya joints with non-zero joint orient
          </li>
        </ul>
        <SubHeading>
           v1.0.3 (10/31/2021)
        </SubHeading>

        <ul>
          <li>
            Initial public release
          </li>
        </ul>

        </BoxCopy>
        </BoxContainer>

        </MainContainer>
    </Layout>
);

export default ContourPatchNotesPage;
